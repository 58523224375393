<template>
  <div id="recruiting">
    <h2>招贤纳士</h2>
    <div class="a_line"></div>
    <div class="company_name">江苏数字矩阵科技有限公司</div>
    <div class="company_intro_content">
      <p>
        公司提倡科学高效的管理，重视建设专家型团队，成立多个评定委员会，
        建立科学公正的岗位等级评定体系；成立企业管理学院，提供学历、
        技能等多方位教育机会；通过实行定向跟踪培养，提供轮岗和自我择岗的机会，
        帮助员工实现个人发展。
      </p>
      <p>
        公司创建了畅通的职业发展通道和可持续的发展空间，分别设置了专业技能成
        长通道和岗位/职务成长通道，提供专业技术与管理技能两个方向的员工职业
        生涯规划，即让不同特长的员工都能实现自我，也为企业培养优秀人才，保障
        企业可持续发展。
      </p>
    </div>
    <div class="recruting_from">
      <div class="recruiting_from from_society">
        <img
          src="~/assets/images/aboutus/SocialRecruitment.png"
          alt="找不到资源"
        />
        <div class="center_of_recruiting society_content">
          <h3>社会招聘</h3>
          <p>/</p>
          <p>Social Recruitment</p>
        </div>
      </div>
      <div class="recruiting_from from_school">
        <img
          src="~/assets/images/aboutus/CampusRecruiting.png"
          alt="找不到资源"
        />
        <div class="center_of_recruiting school_content">
          <h3>校园招聘</h3>
          <p>/</p>
          <p>Campus Recruiting</p>
        </div>
      </div>
    </div>
    <div class="job">
      <div class="job_one" v-for="(item, index) in [1]" :key="index">
        <!-- <div class="jobimg">
          <img
            src="~/assets/images/aboutus/CampusRecruiting.png"
            alt="找不到资源"
          />
        </div> -->
        <div class="jobinfo">
          <div class="jobname">市场销售专员</div>
          <div class="responsibility">
            <span style="font-weight: 800">岗位职责：</span>
            <p>负责国家、省、市、区级科技项目政策解读与研究，为企业提供相关项目申报等技术咨询服务；
            负责企业服务成效、企业基础信息等数据的统计与上报；
            负责公司平台品牌打造、后期维护提升和项目资金申报工作；
            负责完成领导交办的其他工作。</p>
            <p>任职要求：</p>
            <p>1、大专及以上学历，有企业服务、园区运营工作经验；</p>
            <p>2、 对知识产权、企业服务感兴趣，具备一定的企业资源者优先；</p>
            <p>
              3、有丰富的政府资源，了解政府、园区运作、机制和文化，对国家政策具有极高的敏感性；
            </p>
            <p>
              4、熟悉江苏省及南京市政府、科技、经信等相关部门，具备丰富企业项目申报经验，具有高企申报经验优先考虑；
            </p>
            <p>5、了解政府架构，熟悉政府事务，熟悉政府有关部门的工作流程；</p>
            <p>6、有园区管理、孵化器和众创空间管理经验优先考虑；</p>
            <p>
              7、性格开朗，形象气质佳，具备良好的职业仪表，言谈举止稳重大方，工作认真仔细，纪律性强，有良好的保密意识，抗压能力强；
            </p>
          </div>
          <div class="responsibility">
            <span style="font-weight: 800"> 薪资待遇：</span>
            <p>
              1、薪酬：试用期7至15日，转正后底薪5000加业绩提成（业绩越高-底薪越高-提成点越高）月入15k-20k+每月1次（户内/外活动）
              + 团队季度活动+年终奖 + 各项奖励及补贴
            </p>
            <p>
              2、福利：五险一金+带薪年假+节日福利+各项法定假日+各项培训（专业产品知识+销售技巧+政府、企业圈层各方面的行业知识）
            </p>
            <p>
              3、职业晋升规划：销售顾问－销售主管—销售总监——分公司总经理（股东/合伙人）
            </p>
            <p>
              4、提供免费的带薪专业岗位培训及职业生涯规划（为员工从行业专业、职业发展、销售技能等方向提供跟进式培训）
            </p>
            <p>
              5、提供充足的晋升空间和发展机会 工作时间：
              周一至周五，双休，享有国家法定节假日休息。只要对自己工作完成度满意，时间自由
            </p>
            <span style="font-weight: 800"> 职责描述：</span>
            <p>
              负责国家、省、市、区级科技项目政策解读与研究，为企业提供相关项目申报等技术咨询服务；
            </p>
            <p>负责企业服务成效、企业基础信息等数据的统计与上报；</p>
            <p>负责公司平台品牌打造、后期维护提升和项目资金申报工作；</p>
            <p>负责完成领导交办的其他工作。</p>
            <span style="font-weight: 800">任职要求： </span>
            <p>1、 大专及以上学历，有企业服务、园区运营工作经验；</p>
            <p>2、 对知识产权、企业服务感兴趣，具备一定的企业资源者优先；</p>
            <p>
              3、有丰富的政府资源，了解政府、园区运作、机制和文化，对国家政策具有极高的敏感性；
            </p>
            <p>
              4、熟悉江苏省及南京市政府、科技、经信等相关部门，具备丰富企业项目申报经验，具有高企申报经验优先考虑；
            </p>
            <p>5、了解政府架构，熟悉政府事务，熟悉政府有关部门的工作流程；</p>
            <p>6、有园区管理、孵化器和众创空间管理经验优先考虑；</p>
            <p>
              7、性格开朗，形象气质佳，具备良好的职业仪表，言谈举止稳重大方，工作认真仔细，纪律性强，有良好的保密意识，抗压能力强；
            </p>
          </div>
          
          <div class="responsibility">
            <span style="font-weight: 800">人数：</span>若干
          </div>
          <div class="responsibility">
            <span style="font-weight: 800">工作地点：</span>南京
          </div>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "recruiting",
  data() {
    return {};
  },
  created() {
    window.sessionStorage.setItem("aboutus_index", 1);
  },
};
</script>

<style lang="scss" scoped>
  @media(min-width: 1201px){
    @import '@/components/content/aboutus/recruiting.scss';
  }
  @media(max-width: 1200px){
    @import '@/components/content/aboutus/recruiting_phone.scss';
  }
</style>
